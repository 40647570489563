Dropzone.autoDiscover = false;

function toggleAvailability() {
  if ($('.radio-availability:checked').val() == 1) {
    $('.date-availability').hide();
  }
  else {
    $('.date-availability').show();
  }
}

toggleAvailability();

$(function() {

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $('#tabs-slots a').click(function (e) {
    e.preventDefault();
    $('.btn-tabs').removeClass('active');
    $(this).toggleClass('active');
    $(this).tab('show');
  });

  $('.selectpicker').selectpicker({
    noneSelectedText: 'Merci de choisir',
  });
  $('[data-toggle="tooltip"]').tooltip();

  $(document).on('click', '.button-message', function (e) {
    var user_id = $(this).data('user-id');
    console.log(user_id);
    $('input[name$="user_id"]').val(user_id);
    $('textarea[name$="message"]').val('');
  });
  // Dropzone albums
  $('.dropzone-files').dropzone({
    dictDefaultMessage: '<i class="fa fa-download fa-fw"></i> Télécharger vos documents',
    createImageThumbnails: false,
    previewTemplate: "<div></div>",
    success : function(file, res) {
      $('.files').append(res.html);
    }
  });
  // Dropzone profile picture
  $('.dropzone-user').dropzone({
    dictDefaultMessage: '<i class="fa fa-cloud-download fa-4x" style="font-size: 4em;"></i>',
    success : function(file, res) {
      $('.avatar-img').html('<img src="' + res.thumb + '" alt="" class="img-rounded">');
    }
  });
  // Dropzone profile picture
  $('.dropzone-step').dropzone({
    dictDefaultMessage: '<a class="btn btn-default">Télécharger</a>',
    createImageThumbnails: false,
    previewTemplate: '<p class="text-left">Merci !</p>',
    success : function(file, res) {
      $('.avatar-img').html('<img src="' + res.thumb + '" alt="" class="img-rounded">');
    }
  });

  // Dropzone profile picture
  $('.dropzone-company').dropzone({
    dictDefaultMessage: '<i class="fa fa-cloud-download fa-4x"></i>',
    success : function(file, res) {
      $('.logo-img').html(res.thumb);
    }
  });

  $(document).on('change', '.get-sectors', function() {
    var sector_id = $(this).val();
    $.ajax({
      type: 'GET',
      url: '/ajax/sectors/' + sector_id,
      success: function(res) {
        $('.child').html(res.html);
        $('.skill').html(res.html_skill);
        $('.selectpicker').selectpicker('refresh');
      }
    });
  });

  $(document).on('change', '.home-sectors', function() {
    var sector_id = $(this).val();
    $.ajax({
      type: 'GET',
      url: '/ajax/sectors/' + sector_id,
      success: function(res) {
        $('.child').html(res.html);
        $('.skill').html(res.html_skill);
        $('.selectpicker').selectpicker('refresh');
      }
    });
  });

  $(document).on('change', '.search-sectors', function() {
    var sector_id = $(this).val();
    $.ajax({
      type: 'GET',
      url: '/ajax/search-sectors/' + sector_id,
      success: function(res) {
        $('.child').html(res.html);
      }
    });
  });

  $(document).on('submit', '.form-classified-message', function(e) {
    e.preventDefault();
    var href = $(this).attr('action');
    $('.error-message').html('');
    $.ajax({
      type: 'POST',
      data: $(this).serialize(),
      url: href,
      success: function(res) {
        $('.success-message').html(res.success);
      },
      error: function (res) {
        var data = res.responseJSON;
        $.each(data, function(key, value) {
          $('.error-' + key).html(value[0]);
        });
      }
    });
  });

  $(document).on('change', '.radio-availability', function(e) {
    e.preventDefault();
    toggleAvailability();
  });

  $(document).on('click', '.select-slot', function(e) {
    e.preventDefault();
    var slot_id = $(this).data('slot-id');
    var href = $(this).attr('href');
    var current = $(this);
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        current.toggleClass('active');
      }
    });
  });

  $(document).on('click', '.search-slot', function(e) {
    e.preventDefault();
    var slot_id = $(this).data('slot-id');
    var href = $(this).attr('href');
    var current = $(this);
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        current.toggleClass('active');
      }
    });
  });

  $(document).on('click', '.select-day', function(e) {
    e.preventDefault();
    var day_id = $(this).data('day-id');
    var href = $(this).attr('href');
    var current = $(this);
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        $('.slots').html(res.html);
        $('.select-day').removeClass('selected-day');
        current.addClass('selected-day');
        current.focusout();
      }
    });
  });

  $(document).on('click', '.search-last-minute', function(e) {
    e.preventDefault();
    $('.is-last-minute').val(1);
    console.log('test');
    $('.form-search').submit();
  });

  $(document).on('click', '.search-all', function(e) {
    e.preventDefault();
    $('.is-last-minute').val(0);
    $('.form-search').submit();
  });


  $(document).on('click', '.btn-remove-skill', function(e) {
    e.preventDefault();
    var id = $(this).data('id');

    $.ajax({
      type: 'GET',
      url: '/ajax/remove-skill/' + id,
      success: function(res) {
        $('.text-skill').val('');
        $('.list-skills').html(res.html);
      }
    });
  });

  $(document).on('click', '.btn-remove-classified-skill', function(e) {
    e.preventDefault();
    var id = $(this).data('id');

    $.ajax({
      type: 'GET',
      url: '/ajax/remove-skill/' + id + '/1',
      success: function(res) {
        $('.text-skill').val('');
        $('.list-skills').html(res.html);
      }
    });
  });

  $('.btn-add-profile').on('click', function(e) {
    e.preventDefault();
    var id = $(this).data('id');
    $.ajax({
      type: 'GET',
      url: '/ajax/store-profile/' + id,
      success: function(res) {
        $('.profile-' + id).toggleClass('active-profile');
        $('.total-cv').html(res.total_cv);
        $('.total-price').html(res.total_price);
      }
    });
  });


  $('.btn-add-skill').on('click', function(e) {
    e.preventDefault();
    var text_skill = $('.text-skill').val();
    $('.msg-error').html('');
    $.ajax({
      type: 'GET',
      url: '/ajax/store-skill/' + text_skill,
      success: function(res) {
        $('.text-skill').val('');
        $('.list-skills').html(res.html);
      },
      error: function (res, status, error) {
        $('.msg-error').html(res.responseJSON.message);
      }
    });
  });

  $('.btn-add-classified-skill').on('click', function(e) {
    e.preventDefault();
    var text_skill = $('.text-skill').val();

    $.ajax({
      type: 'GET',
      url: '/ajax/store-skill/' + text_skill + '/1',
      success: function(res) {
        $('.text-skill').val('');
        $('.list-skills').html(res.html);
      }
    });
  });

  $(document).on('click', '.save-search', function (e) {
    e.preventDefault();
    $.ajax({
      type: 'GET',
      url: '/ajax/save-search',
      success: function(res) {
        swal('', "Vous êtes inscrit à cette recherche !", "success");
      }
    });
  });

  $('.add-lang').on('submit', function (e) {
    e.preventDefault();
    $form = $(this);
    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
      success: function (html) {
        $('.jobs-languages').append(html);
        $(".add-lang select").each(function( index ) {
          $(this).val($("option:first", this).val());
        });
        $('.add-lang textarea').val('');
      }
    });
  });

  $(document).on('submit', '.remove-lang', function(e) {
    e.preventDefault();
    $form = $(this);
    $row = $form.closest( "tr" );
    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
      success: function (lang) {
        $row.remove();
      }
    });
  });

  $('.add-experience').on('submit', function (e) {
    e.preventDefault();
    $form = $(this);
    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
      success: function (html) {
        $('.jobs-experiences').append(html);
        $('.add-experience input, .add-experience textarea').val('');
      }
    });
  });

  $(document).on('submit', '.remove-experience', function(e) {
    e.preventDefault();
    $form = $(this);
    $row = $form.closest( "tr" );
    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
      success: function (lang) {
        $row.remove();
      }
    });
  });

  $('.add-formation').on('submit', function (e) {
    e.preventDefault();
    $form = $(this);
    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
      success: function (html) {
        $('.jobs-formations').append(html);
         $(".add-formation select").each(function( index ) {
          $(this).val($("option:first", this).val());
        });
        $('.add-formation input, .add-formation textarea').val('');
      }
    });
  });

  $(document).on('submit', '.remove-formation', function(e) {
    e.preventDefault();
    $form = $(this);
    $row = $form.closest( "tr" );
    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
      success: function (lang) {
        $row.remove();
      }
    });
  });

  $(document).on('click', '.select-job', function(e) {
    e.preventDefault();
    var job_id = $(this).data('job-id');
    var href = '/ajax/select-job/' + job_id;
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        if (res.status) {
          $('.job-' + job_id).removeClass('btn-default');
        }
        else {
          $('.job-' + job_id).removeClass('btn-success');
        }
        $('.job-' + job_id).addClass(res.class, 500);
        $('.job-' + job_id).html(res.html);
        $('.total').html(res.total);
      }
    });
  });

  $(document).on('click', '.select-job-day', function(e) {
    e.preventDefault();
    var job_id = $(this).data('job-id');
    var href = $(this).attr('href');
    var current = $(this);
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        $('.slots').html(res.html);
        $('.job-day-' + job_id).removeClass('selected-day');
        current.addClass('selected-day');
        current.blur();
      }
    });
  });

  $(document).on('click', '.save-job-slot', function(e) {
    e.preventDefault();
    var href = $(this).attr('href');
    var current = $(this);
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        current.toggleClass('active');
        current.blur();
      }
    });
  });

  $(document).on('change', '.get-skills', function() {
    var sector_id = $(this).val();
    $.ajax({
      type: 'GET',
      url: '/ajax/skills/' + sector_id,
      success: function(res) {
        $('.skill').html(res.html);
        $('.skills').selectpicker();
      }
    });
  });

  $(document).on('change', '.check-same', function() {
    var classified_id = $(this).data('classified-id');
    var job_id = $(this).val();
    $.ajax({
      type: 'GET',
      url: '/ajax/check-same/' + job_id + '/' + classified_id,
      success: function(res) {
        $('.percentage').html(res.value + '%');
        $('.percentage').removeClass('text-danger text-info text-warning text-success');
        $('.percentage').addClass(res.class);
      }
    });
  });

  $(document).on('submit', '.send-message', function(e) {
    e.preventDefault();
    var form = $(this);
    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      success: function(res) {
        $('#message-modal').modal('hide');
      },
      error: function (res) {
        var data = res.responseJSON;
        $.each(data, function(key, value) {
          $('.error-' + key).html(value[0]);
        });
      }
    });
  });

  /*$(document).on('change', '.upload-avatar', function(e) {
    e.preventDefault();
    console.log('test');
    $('.upload-avatar-form').submit();
  });

  $(document).on('submit', '.upload-avatar-form', function(e) {
    e.preventDefault();
    var form = $(this);
    //var form_data = new FormData($(this));
    $.ajax({
      type: 'POST',
      url: '/ajax/upload-avatar',
      data: new FormData('form'),
      success: function(res) {

      }
    });
  });*/

  $(document).on('change', '.get-types', function() {
    var sector_id = $(this).val();
    $.ajax({
      type: 'GET',
      url: '/ajax/types/' + sector_id,
      success: function(res) {
        $('.company-types').html(res.html);
      }
    });
  });

  $(document).on('click', '.btn-type', function(e) {
    e.preventDefault();
    var type = $(this).data('type');
    $('.btn-type').toggleClass('active');
    $('.type').val(type);
    if (type) {
      $('.search-all').html('Voir les candidats');
      $('.search-last-minute').hide();
    }
    else {
      $('.search-all').html('Afficher les annonces');
      $('.search-last-minute').show();
    }
  });

  $(document).on('click', '.load-messages', function(e) {
    e.preventDefault();
    var href = $(this).attr('href');
    $.ajax({
      type: 'GET',
      url: href,
      success: function(res) {
        $('.load-count').html(res.total);
        if (!res.load) {
          $('.load-messages').hide();
        }
        $('.load-messages').attr('href', res.route);
        $('.messages').prepend(res.html);
      }
    });
  });

  $(document).on('click', '.confirm-delete', function (e) {
    e.preventDefault();
    var href = $(this).attr('href');
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this imaginary file!",
      type: "error",
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      confirmButtonText: 'OK',
      cancelButtonText: 'Annuler'
    },
    function() {
      $(location).attr('href', href);
    });
  });

  $(document).on('click', '.job-file-delete', function (e) {
    e.preventDefault();
    var href = $(this).attr('href');
    swal({
      title: "Are you sure ?",
      text: "You will not be able to recover this imaginary file!",
      type: "error",
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      confirmButtonText: 'OK',
      cancelButtonText: 'Annuler'
    },
    function() {
      $.ajax({
        type: 'GET',
        url: href,
        success: function(res) {
          $('.file-' + res.id).hide('slow');
        }
      });
    });
  });

  $('.search-company').autocomplete({
    source: function( request, response ) {
      $.ajax({
        url: '/ajax/search-company',
        dataType: 'json',
        data: {
          q: request.term
        },
        success: function(data) {
          response(data);
        }
      });
    },
    select: function(event, ui) {
      $('.company-id').val(ui.item.id);
    },
  });
  // this identifies your website in the createToken call below
  Stripe.setPublishableKey(window.stripe_key);
  function stripeResponseHandler(status, response) {
    if (response.error) {
      // re-enable the submit button
      $('.payment-btn').removeAttr("disabled");
      // show the errors on the form
      $(".payment-errors").html(response.error.message);
    } else {
      var form$ = $("#payment-form");
      // token contains id, last4, and card type
      var token = response['id'];
      // insert the token into the form so it gets submitted to the server
      form$.append("<input type='hidden' name='stripeToken' value='" + token + "' />");
      // and submit
      form$.get(0).submit();
    }
  }

  $("#payment-form").submit(function(event) {
    //$('.payment-btn').html('<i class="fa fa-spinner fa-pulse fa-fw loading"></i> ' + $('.payment-btn').html());
    // disable the submit button to prevent repeated clicks
    $('.payment-btn').attr("disabled", "disabled");
    // disable the submit button to prevent repeated clicks
    //$('.submit-button').attr("disabled", "disabled");
    // createToken returns immediately - the supplied callback submits the form if there are no errors
    Stripe.createToken({
      number: $('.card-number').val(),
      cvc: $('.card-cvc').val(),
      exp_month: $('.card-expiry-month').val(),
      exp_year: $('.card-expiry-year').val()
    }, stripeResponseHandler);
    return false; // submit from callback
  });
});
