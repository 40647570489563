// Menu mobile - open & close
$('#navbar-mobile-icon').click(function() {
  $('#navbar-mobile-icon .glyphicon').toggleClass('glyphicon-menu-hamburger');
  $('#navbar-mobile-icon .glyphicon').toggleClass('glyphicon-remove');
  $('#navbar-overlay-menu').toggleClass('open');
  $('nav').toggleClass('fixed');
});

// Menu mobile - filters button
$('.menu-filter').click(function() {
  $(this).toggleClass('active');
  $('.menu-filter-list').toggleClass('open');
});

// Filter bar change
$('#lastminute-link').click(function(){
  $('ul.checkbox-list.offers').addClass('hidden');
  $('ul.checkbox-list.last-minute').removeClass('hidden');
});

$('#offers-link').click(function(){
  $('ul.checkbox-list.last-minute').addClass('hidden');
  $('ul.checkbox-list.offers').removeClass('hidden');
});

// Tooltip
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
})

// Range slider
$('#ex1').bootstrapSlider({
  formatter: function(value) {
    return value + 'km';
  },
  change: function(oldValue, newValue) {
    $('input[name="range"]').val(newValue);
  }
});
